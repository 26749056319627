<template>
<div>
<v-row
    align="center"
    justify="center"
>
 <v-col
      cols="11"
      md="5"
      align="center"
    justify="center"
>
 <v-hover
        v-slot="{ hover }"
        open-delay="200"
      >

      <v-card
          :elevation="hover ? 16 : 2"
          :class="{ 'on-hover': hover, padding: 10 }"
          class="mt-15 d-flex align-content-center flex-wrap"
          min-height="400"
          min-width="350"
          max-width="500"
        >

        <v-container>
        <v-img
          class="mb-3"
          height="160"
          src="@/assets/login.png"
        ></v-img>

        <v-form
          v-on:submit.prevent="ValidarLogin()"
          ref="form"
          lazy-validation
        >
          <v-text-field
            v-model="login.usuario"
            :rules="UsuarioRules"
            :loading="login.usuario == '' ? true : false"
            label="Usuario"
            required
            type="number"
          ></v-text-field>

          <v-text-field
            :type="checkbox ? 'text' : 'password'"
            v-model="login.pass"
            :rules="PassRules"
            :loading="login.usuario != '' && login.pass == '' ? true : false"
            label="Contraseña"
            required
          ></v-text-field>

          <v-checkbox
          class="mt-n2"
            v-model="checkbox"
            label="Ver Contraseña"
          ></v-checkbox>

          <vue-recaptcha 
            class="mt-n2" 
            :sitekey="key"
            :loadRecaptchaScript="true"
            @verify="ValidarRecaptcha"
          ></vue-recaptcha>
<!--
          <v-col cols="9">
            <h4 v-if="!recaptcha" class="error--text">¡Verificar Recaptcha!</h4>
          </v-col>
-->
          <v-btn
            type="submit"
            :disabled="!botonlogin"
            color="success"
            class="mr-4 mt-2"
            @click="validate"
          >
            Ingresar
            <v-icon
                dark
                right
              >
                mdi-checkbox-marked-circle
              </v-icon>
          </v-btn>

        </v-form>
        </v-container>
      </v-card>
    </v-hover>
  </v-col>
</v-row>
</div>
</template>

<script>
  import VueRecaptcha from 'vue-recaptcha';
  import { mapState, mapActions } from 'vuex'

  export default {
    components: { VueRecaptcha },

    watch :{
      recaptcha:function(){
        setTimeout(() => { this.recaptcha = null }, 58000);
      }
    },

    data: () => ({
      key:"6LdMbDYbAAAAAKgrofMJsvtgxsEqjfqbJRM2cj7_",
      recaptcha: null,
      checkbox: false,
      login: {usuario: '', pass: ''},
      UsuarioRules: [
        v => !!v || 'Usuario Requerido',
        v => (v && v.length >= 7) || 'Usuario debe tener al menos 7 caracteres',
        v => (v && v.length <= 10) || 'Usuario debe menor o igual a 10 caracteres',
      ],
      PassRules: [
        v => !!v || 'Contraseña Requerida',
        v => /^[@]+/.test(v) || 'Contraseña debe comenzar con @',
        v => (v && v.length >= 8) || 'Contraseña debe tener al menos 8 caracteres',
        v => /[A-Z]+/.test(v) || 'La contraseña debe tener al menos 1 letra mayuscula.',
        v => /[a-z]+/.test(v) || 'La contraseña debe tener al menos 1 letra minuscula.',
        v => /[0-9]+/.test(v) || 'La contraseña debe contener al menos 1 número.',
      ],
      checkbox: false,
    }),

    methods: {
      ...mapActions(['LoginSistem']),

      validate () {
        this.$refs.form.validate()
      },

      reset () {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      },

      ValidarRecaptcha (e) {
        this.recaptcha = e;
      },

      ValidarLogin(){
        if(this.recaptcha){
          this.LoginSistem(this.login)
        }
      }

    },

    computed:{
      ...mapState(['UsuarioLogueado', 'token']),

      botonlogin: function(){
        var bandera = false;
        if(this.login.usuario != '' && this.login.usuario.length >= 5 && this.login.pass != '' && this.login.pass.length >= 5 && this.recaptcha){
          bandera = true;
        }
        return bandera;
      },

      redirect: () => {
          if(token){
            route.push('/').catch(()=>{});
          }
      }

    }
  }
</script>